<template>
  <div class="layout full-size">
    <router-view />
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.layout {
  // padding: 2px;
}
</style>
